import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LoginService } from "../../../services/login.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit(): void { }
  //Botones para desactivar
  @ViewChild('btn1') btn1: ElementRef; icn1 = false;
  //variables
  mostrarError = false;

  login(f) {
    //Desactivar btns
    this.btn1.nativeElement.disabled = true; this.icn1 = true;

    this.loginService.login(f.value.user, f.value.pass).subscribe(
      (data: any) => {
        if (data.verificado === true) {
          //Activar btns
          //this.btn1.nativeElement.disabled = false; this.icn1 = false;

          localStorage.setItem("token", data.token);
          localStorage.setItem("id", data.id);
          localStorage.setItem("rol", data.rol);

          this.mostrarError = false;
          this.redireccionar();
        } else {
          //Activar btns
          this.btn1.nativeElement.disabled = false; this.icn1 = false;

          this.mostrarError = true;
        }
      },
      (error) => {
        //Activar btns
        this.btn1.nativeElement.disabled = false; this.icn1 = false;

        this.mostrarError = true;
      }
    );
  }

  redireccionar() {
    var rol = localStorage.getItem("rol");
    var token = localStorage.getItem("token");
    this.loginService.verificarRol(rol).subscribe(
      (data: any) => {
        if (data.rol === "Cliente") {
          this.router.navigate(["cliente"]);
        } else if (data.rol === "Administrador") {
          this.router.navigate(["administrador/inicio"]);
        } else if (data.rol === "Repartidor") {
          this.router.navigate(["repartidor"]);
        }
      },
      (error) => {
        this.mostrarError = true;
      }
    );
  }
}
