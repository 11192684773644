import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class RolAdministrador implements CanActivate {

    constructor(private rotuer: Router) { }
    jwtHelper = new JwtHelperService();

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let token = localStorage.getItem('token');
        const payload = this.jwtHelper.decodeToken(token);
        if (payload.rol === 'Administrador') {
            return true;
        } else {
            this.rotuer.navigate(['login']);
            return false;
        }
    }
}