import { INavData } from "@coreui/angular";

export const navItemsAdministrador: INavData[] = [
  {
    name: "Inicio",
    url: "/administrador/inicio",
    icon: "fas fa-home",
  },
  {
    name: "Pedidos",
    url: "/administrador/pedidos",
    icon: "fas fa-clipboard-list",
    children: [
      {
        name: "Negocios",
        url: "/administrador/pedidos/negocios",
        icon: "fas fa-store",
      },
      {
        name: "Particulares",
        url: "/administrador/pedidos/particulares",
        icon: "fas fa-user",
      },
      {
        name: "No pagos",
        url: "/administrador/pedidos/nopagos",
        icon: "fas fa-money-bill"
      },
    ],
  },
  {
    name: "Productos",
    url: "/administrador/productos",
    icon: "fas fa-shopping-basket",
  },
  {
    name: "Mercadería",
    url: "/administrador/mercaderia",
    icon: "fas fa-shopping-basket",
  },
  {
    name: "Clientes",
    url: "/administrador/clientes",
    icon: "fas fa-user-friends",
    children: [
      {
        name: "Negocios",
        url: "/administrador/clientes/negocios",
        icon: "fas fa-store",
      },
      {
        name: "Particulares",
        url: "/administrador/clientes/particulares",
        icon: "fas fa-user",
      },
    ],
  },
  {
    name: "Sin Visitar",
    url: "/administrador/sinvisitar",
    icon: "fas fa-store-alt",
  },
  {
    name: "Repartidores",
    url: "/administrador/repartidores",
    icon: "fas fa-truck",
  },
  {
    name: "Estadísticas",
    url: "/administrador/estadisticas",
    icon: "fas fa-chart-line",
  },
  {
    name: "Configuración",
    url: "/administrador/configuracion",
    icon: "fas fa-user-edit",
  },
];
