import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login.service';


@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    data: any;

    constructor(public router: Router, public loginService: LoginService) { }


    canActivate(): boolean {
        if (!this.loginService.isAuthenticated()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}