import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/shared/error/404.component";
import { LoginComponent } from "./views/shared/login/login.component";
import { LoginGuard } from './guards/login.guard';
import { UnLoginGuard } from './guards/unlogin.guard';
import { RolAdministrador } from './guards/roladministrador.guard';
import { RolCliente } from './guards/rolcliente.guard';
import { RolRepartidor } from './guards/rolrepartidor.guard';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [UnLoginGuard],
    data: {
      title: "Login Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [LoginGuard],
    data: {
      title: "Home",
    },
    children: [
      {
        path: "administrador",
        loadChildren: () =>
          import("./views/administrador/administrador.module").then((m) => m.AdministradorModule),
        canActivate: [LoginGuard, RolAdministrador],
      },
      {
        path: "cliente",
        loadChildren: () =>
          import("./views/cliente/cliente.module").then((m) => m.ClienteModule),
        canActivate: [LoginGuard, RolCliente
        ],
      },
      {
        path: "repartidor",
        loadChildren: () =>
          import("./views/repartidor/repartidor.module").then((m) => m.RepartidorModule),
        canActivate: [LoginGuard, RolRepartidor],
      },
    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
