import { INavData } from "@coreui/angular";

export const navItemsCliente: INavData[] = [
  {
    name: "Inicio",
    url: "/cliente/inicio",
    icon: "fas fa-home",
  },
  {
    name: "Pedido",
    url: "/cliente/pedido",
    icon: "fas fa-shopping-cart",
  },
  {
    name: "Retiros",
    url: "/cliente/retiros",
    icon: "fas fa-clipboard-list",
    children: [
      {
        name: "Pendientes",
        url: "/cliente/retiros/pendientes",
        icon: "fas fa-times",
      },
      {
        name: "Entregados",
        url: "/cliente/retiros/entregados",
        icon: "fas fa-check",
      },
    ],
  },
  {
    name: "Historial",
    url: "/cliente/historial",
    icon: "fas fa-clipboard-list",
  },
  {
    name: "Repartos",
    url: "/cliente/repartos",
    icon: "fas fa-list",
  },
  {
    name: "Configuración",
    url: "/cliente/configuracion",
    icon: "fas fa-user-edit",
  },
];
