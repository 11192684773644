import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { JwtHelperService } from "@auth0/angular-jwt";


@Injectable({
    providedIn: 'root'
})
export class UnLoginGuard implements CanActivate {

    data: any;

    constructor(public router: Router, public loginService: LoginService) { }
    jwtHelper = new JwtHelperService();


    canActivate(): boolean {
        if (this.loginService.isAuthenticated()) {
            let token = localStorage.getItem('token');
            const payload = this.jwtHelper.decodeToken(token);

            if (payload.rol == 'Administrador') {
                this.router.navigate(['administrador/inicio']);
            }
            if (payload.rol == 'Cliente') {
                this.router.navigate(['cliente/inicio']);
            }
            if (payload.rol == 'Repartidor') {
                this.router.navigate(['/repartidor/pedidos']);
            }
            return false;
        }
        return true;
    }
}