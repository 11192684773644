import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Url } from "../models/url";
import { map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'any',
})
export class LoginService {
  constructor(public http: HttpClient) { }
  token = localStorage.getItem("token");
  rol = localStorage.getItem("rol");
  jwtHelper = new JwtHelperService();

  headers = {
    headers: new HttpHeaders({
      token: this.token,
      rol: this.rol,
    }),
  };

  url = Url;

  login(usuario, password) {
    return this.http
      .post(`${this.url}usuario/login`, {
        usuario: usuario,
        password: password,
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  verificarRol(rol) {
    return this.http
      .post(`${this.url}usuario/verificarrol`, {
        rol: rol,
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  verificarId(id) {
    return this.http
      .post(`${this.url}usuario/verificarid`, { id })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  nuevo(c, rol) {
    return this.http
      .post(`${this.url}usuario/registro`, {
        usuario: c.email,
        password: c.clave,
        rol: rol,
      },
        this.headers
      ).pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  modificarPasswordPorAdministrador(u, id) {
    return this.http
      .put(`${this.url}usuario/modificarPasswordPorAdministrador/${id}`, {
        usuario: u.usuario,
        password: u.clave,
      },
        this.headers
      ).pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  modificarPasswordPorUsuario(id, antiguaPassword, nuevaPassword) {
    return this.http
      .put(`${this.url}usuario/modificarPasswordPorUsuario`, {
        id: id,
        antiguaPassword: antiguaPassword,
        nuevaPassword: nuevaPassword
      },
        this.headers
      ).pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    return !this.jwtHelper.isTokenExpired(token);
  }

  public refrescar() {
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('rol');
    location.reload();
  }
}
