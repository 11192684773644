import { INavData } from "@coreui/angular";

export const navItemsRepartidor: INavData[] = [
  {
    name: "Pedidos",
    url: "/repartidor/pedidos",
    icon: "fas fa-clipboard-list",
  },
  {
    name: "Negocios",
    url: "/repartidor/negocios",
    icon: "fas fa-store",
  },
];
