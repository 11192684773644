import { Component } from "@angular/core";
import { navItemsAdministrador } from "../../views/administrador/_nav";
import { navItemsCliente } from "../../views/cliente/_nav";
import { navItemsRepartidor } from "../../views/repartidor/_nav";
import { LoginService } from "../../services/login.service";
import { Router } from "@angular/router";


@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems: any;

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {

    const rol = localStorage.getItem("rol");
    this.loginService.verificarRol(rol).subscribe(
      (data: any) => {
        if (data.rol === "Cliente") {
          this.navItems = navItemsCliente;
        } else if (data.rol === "Administrador") {
          this.navItems = navItemsAdministrador;
        } else if (data.rol === "Repartidor") {
          this.navItems = navItemsRepartidor;
        }
      },
      (error) => { }
    );
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  cerrarSesion() {
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('rol');
    location.reload();
  }
}
